@import 'colors';
@import 'shadows';

.SearchContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.SearchBox {
    display: flex;
    @include BoxShadow(1);
    border-radius: 4px;
    background-color: $light;

    width: 500px;

    padding: 8px;
    transition: border-bottom 0.3s;
    margin-bottom: 16px;
    align-items: center;

    &:hover {
        cursor: text;
    }

    input {
        border: none;
        border-color: transparent;
        outline: none;
        width: 100%;
    }

    svg {
        fill: lighten($dark, 50);
    }

    &.selected {
        border-bottom: 2px solid $primary;
    }

    & div {
        display: flex;
        position: absolute;
        white-space: nowrap;
        align-items: center;
        font-size: 14px;
        background-color: $primary;
        line-height: 0;
        padding: 0 8px;
        border-radius: 4px;
        color: $light;
        user-select: none;
        transition: background-color 0.3s, box-shadow 0.3s, opacity 0.3s;

        @include BoxShadow(1);

        &:hover {
            cursor: pointer;
            background-color: darken($primary, 5);

            @include BoxShadow(2);
        }

        &:active {
            background-color: lighten($primary, 10);
        }

        &.hidden {
            opacity: 0;
            display: none;
        }

        svg {
            position: relative;
            top: 1px;
            fill: $light;
            width: 14px;
            padding-left: 4px;
        }
    }
}

.SearchResults {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 56px;
    border-radius: 4px;
    width: 500px;

    color: lighten($dark, 25);

    background-color: $light;
    @include BoxShadow(2);

    .Item {

        padding: 4px;

        &:hover {
            cursor: pointer;
            background-color: lighten($dark, 80);
        }
    }
}
