@import 'colors';
@import 'shadows';

.ClickableTable {
    @include BoxShadow(1);

    border-radius: 4px;
    border-collapse: collapse;

    width: 100%;
    /* table-layout: fixed; */

    tr td, tr th {
        padding: 8px 16px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .TableHeader {
        background-color: $dark;
        color: $light;

        th {
            font-weight: normal;
            user-select: none;

            &:hover {
                cursor: pointer;
                background-color: lighten($dark, 10);
                @include BoxShadow(2);
            }
        }

        th:first-child {
            border-top-left-radius: 4px;
        }

        th:last-child {
            border-top-right-radius: 4px;
        }
    }

    .TableData {

        tr {
            display: block;
        }

        &:hover {
            cursor: pointer;
            td {
                background-color: darken($light, 7);
            }
        }

        td {
            border-bottom: 1px solid lighten($dark, 70);
            background-color: $light;
        }

        &:last-child td {
            border: none;
        }
    }
}
